import React from "react"
import { PageProps, Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"
import {
  faShieldVirus,
  faChartLine,
  faDollarSign,
  faHeartbeat,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/PageLayout"
import SEO from "../../components/seo"
import Hero from "../../components/Hero"
import ContactForm from "../../components/ContactForm"
import SalesPoint from "../../components/SalesPoint"

import HeaderIcon from "./images/IoTHeaderIcon.svg"
import ScaleIcon from "./images/Scale.png"
import SecureIcon from "./images/Secure.png"
import MoneyIcon from "./images/SaveMoney.png"
import ValueIcon from "./images/Value.png"
import HealthIcon from "./images/Health.png"

const IOTConsultancy = (props: PageProps) => (
  <Layout
    title={"IoT Consultancy"}
    hero={
      <Hero
        title="IoT Consultancy"
        subtitle="Ingest, store and analyse vast amounts of sensor data, all while saving money."
        icon={HeaderIcon}
      />
    }
  >
    <SEO
      title="IoT Consultancy"
      description="Make sense of your remote sensor data while saving money."
    />
    <Row>
      <Col sm={12} md={12} lg={8}>
        <div>
          <p className="lead">
            The Internet of Things, or IoT, is a way to describe the smart
            things that are rapidly being connected to the internet. Freshnode
            can partner with you to take control of your IoT fleet and realise
            true value from it, for you and your users.
          </p>

          <hr className="my-4" style={{ borderTopStyle: "dotted" }} />

          <SalesPoint icon={faShieldVirus} title={"Secure your IoT operations"}>
            Personal info breaches, unauthorised access, data theft and more are
            on the rise. 54% of organisations have experienced at least one
            security incident on their industrial control system over a 12 month
            period. Don't become another statistic. We will help you understand
            your IoT security landscape and tighten it up.
          </SalesPoint>

          <SalesPoint
            icon={faChartLine}
            title={"Effortlessly scale to meet demand"}
          >
            Moving IoT workloads to the cloud provides resilience, reliability
            and peace of mind that you could only dream of with a hand rolled
            solution. We have experience designing, building and operating IoT
            systems at massive scale. Rest assured, you'll be in safe hands with
            us.
          </SalesPoint>

          <SalesPoint icon={faDollarSign} title={"Save money"}>
            Traditional systems from many vendors use large server farms. Due to
            the irregular nature of IoT data streams, this leads to
            under-utilisation and a waste of your money. We favour architecting
            solutions around the{" "}
            <Link to="/services/serverless">Serverless model</Link>, meaning
            that you only pay for the resources you use.
          </SalesPoint>

          <SalesPoint
            icon={faHeartbeat}
            title={"Automatically detect when things are going wrong"}
          >
            How do you know if your processes are working as they should? If you
            are not being informed the second things aren't right, then you are
            losing valuable time to correct them. We can use the power of the
            cloud to detect anomolies and evaluate your fleet health, so you
            know what's going on at all times.
          </SalesPoint>

          <SalesPoint
            icon={faThumbsUp}
            title={"Realise the true value of your data"}
          >
            Using advanced data processing pipelines, we can help you ingest,
            enrich and query terabytes of data in a predictable way. All data is
            exposed through traditional SQL-like interfaces, meaning you can
            continue to use existing skills and expertise in your organisation.
          </SalesPoint>
        </div>
      </Col>
      <Col>
        <ContactForm />
      </Col>
    </Row>
  </Layout>
)

export default IOTConsultancy
